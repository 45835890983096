import React from "react";
import { DarkModeProvider } from "./DarkModeContext";
import MainPage from "../MainPage/MainPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BlogsPage from "../BlogsPage/BlogsPage";
import "./App.css";
import About from "../AboutMe/About";
import Contact from "../Contact/Contact";
import Blog1 from "../Blogs/Transition";
import Blog2 from "../Blogs/CloudResume";
import Blog3 from "../Blogs/WebScraping";

function App() {
  return (
    <DarkModeProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/blog/1" element={<Blog1 />} />
            <Route path="/blog/2" element={<Blog2 />} />
            <Route path="/blog/3" element={<Blog3 />} />
          </Routes>
        </div>
      </Router>
    </DarkModeProvider>
  );
}

export default App;
