import React, { useEffect } from "react";
import { useDarkMode } from "../App/DarkModeContext";
import mypic from "./mypic.jpg";
import LatestBlogs from "./LatestBlogs";
import "../App/App.css";

const MainContent = () => {
  const { isDarkMode } = useDarkMode();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`main-content ${isDarkMode ? "dark" : ""}`}>
      {/* Hero Section */}
      <div className={`hero-section ${isDarkMode ? "dark" : ""}`}>
        <div className="overlay">
          <img src={mypic} alt="Harsha Vallabhaneni" className="mypic" />
          <h1 className="name">Harsha Vallabhaneni</h1>
          <h1 className="tagline">
            Software Developer, Self-development Seeker, and a Culinary Explorer
          </h1>
        </div>
      </div>

      {/* Content below the Hero Section */}
      {/* Short Introduction */}
      <div className={`short-introduction ${isDarkMode ? "dark" : ""}`}>
        <p>
          <span className="highlight">Hello and welcome!</span> I am a person of
          many interests, ever eager to learn and grow. When I'm not deeply
          involved in the <span className="highlight">ever-evolving realm</span>{" "}
          of technology, you can find me delving into{" "}
          <span className="highlight">diverse subjects</span>—be it the{" "}
          <span className="highlight">art of cooking</span>, the pursuit of{" "}
          <span className="highlight">mental and physical well-being</span>, or
          delving deep into the fascinating realms of{" "}
          <span className="highlight">scientific research</span> and{" "}
          <span className="highlight">futuristic tech</span>.
        </p>
      </div>

      {/* Latest Blogs */}
      <LatestBlogs isDarkMode={isDarkMode} />
    </div>
  );
};

export default MainContent;
