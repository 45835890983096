import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useDarkMode } from "../App/DarkModeContext";
import "./About.css";

const About = () => {
  const { isDarkMode } = useDarkMode();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className={`container ${isDarkMode ? "dark" : ""}`}>
        <div className={`about-container ${isDarkMode ? "dark" : ""}`}>
          <h1>A journey of Tech, Taste, and Transformation!</h1>
          <p>
            Welcome to my tech-infused world of exploration and discovery!
            <br />
            I'm Harsha Vallabhaneni, a software engineer, self-development
            seeker, and culinary aficionado, on a mission to unravel the wonders
            of technology while embracing the flavors of life. Join me as we
            delve into the realms of software engineering, self-improvement, and
            the fascinating frontiers of science, one adventure at a time.
          </p>
          <p>
            My Career in Tech - Embracing the Cloud:
            <br />
            At the heart of my professional journey lies a burning passion for
            cloud computing and emerging technologies. As a Cloud Engineer and
            DevOps enthusiast, I am constantly on the quest to master the art of
            cloud architecture and automation. My fascination with the seamless
            scalability, security, and innovation that the cloud offers has been
            the driving force behind my career transition from Mechanical
            Engineering to the dynamic world of IT.
          </p>
          <p>
            Researching Science and Future Technologies:
            <br />
            My inquisitive mind finds fascination in exploring the latest
            developments in science and future technologies. From artificial
            intelligence to space exploration, I delve into the frontiers of
            human knowledge, marveling at the possibilities that lie ahead. I
            enjoy engaging in thought-provoking discussions about the potential
            impact of these innovations on our society and the world at large.
          </p>
          <p>
            Self-Development and Reading:
            <br />
            As an avid reader, I find solace in the pages of self-development
            books and thought-provoking articles. This habit of continuous
            learning fuels my personal growth, helping me evolve into a better
            version of myself with each passing day. I believe in the power of
            self-awareness and self-improvement to unlock our full potential.
          </p>
          <p>
            Cooking and Running:
            <br />
            In the kitchen, I uncover my creative side, experimenting with
            flavors and cuisines to craft delicious dishes. The art of cooking
            allows me to unwind and express my love for good food, which I share
            with friends and family to create lasting memories. On the flip
            side, I lace up my running shoes to embrace the exhilarating freedom
            that running offers. It's a chance to connect with nature, clear my
            mind, and challenge my physical limits.
          </p>
          <p>
            Embracing a Balanced Life:
            <br />
            Through my blogging journey, I hope to share my experiences and
            insights from this diverse array of interests. I believe in leading
            a balanced life where each facet complements and enriches the other.
            As we explore tech wonders and self-discovery, let's cherish the
            moments of joy in the kitchen, savor the sense of achievement in
            running, and marvel at the boundless potential of science and future
            technologies.
          </p>
          <p>
            Join me on this exciting adventure, where we celebrate the beauty of
            embracing our passions, curiosity, and the limitless potential of
            the software world. Together, let's learn, grow, and savor the
            beauty of life in all its magnificent dimensions.
          </p>
          <p>Stay curious, stay inspired!</p>
          <p>Harsha Vallabhaneni</p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default About;
