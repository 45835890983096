import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useDarkMode } from "../App/DarkModeContext";
import "./Contact.css";

const Contact = () => {
  const { isDarkMode } = useDarkMode();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className={`container ${isDarkMode ? "dark" : ""}`}>
        <div className={`contact-container ${isDarkMode ? "dark" : ""}`}>
          <h1>Let's Connect!</h1>
          <p>
            I would love to connect with fellow tech enthusiasts, foodies,
            runners, and anyone intrigued by the wonders of science and
            technology. Whether you have questions, ideas for collaboration, or
            simply want to share your own experiences, feel free to drop me a
            line! Let's embark on this exciting journey together.
          </p>
          <p>Here are the best ways to reach me:</p>
          <div className="contact-social-icons">
            <a
              className="contact-social-icon"
              href="https://www.linkedin.com/in/sai-harsha-vallabhaneni-5a4480129/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
            <a
              className="contact-social-icon"
              href="https://www.instagram.com/_harsha27/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
            <a
              className="contact-social-icon"
              href="https://github.com/Harsha27999"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-github"></i>
            </a>
            <a
              className="contact-social-icon"
              href="https://www.facebook.com/harsha.vallabhaneni.27"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              className="contact-social-icon"
              href="https://twitter.com/HarshaVallabha1"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-twitter"></i>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
