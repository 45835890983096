import React, { useState, useEffect } from "react";
import "./Footer.css";
import { useDarkMode } from "../App/DarkModeContext";
import "font-awesome/css/font-awesome.min.css";

const Footer = () => {
  const { isDarkMode } = useDarkMode();
  const [views, setViews] = useState(0); // State to store the viewer count

  useEffect(() => {
    async function updateCounter() {
      try {
        const response = await fetch(
          "https://apznjbhc2i2b2wzozrrgd3hx3e0ocxzo.lambda-url.ap-south-1.on.aws/"
        );
        const data = await response.json();
        setViews(data.views);
      } catch (error) {
        console.error("Error fetching viewer count:", error);
      }
    }

    updateCounter();
  }, []); // Empty dependency array means this useEffect runs once when the component mounts

  return (
    <div className={`Footer ${isDarkMode ? "dark" : ""}`}>
      <div className={`footer-container ${isDarkMode ? "dark" : ""}`}>
        {/* Testimonials */}
        <div className="testimonials">
          <p>
            "It's not what happens to you, but how you react to it that matters"
            – Epictetus
          </p>
          <p className="signature">Made with ❤️, Harsha.</p>
          <p className="views">👀 Views: {views}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
