import React from "react";
import Header from "../Header/Header";
import MainContent from "./MainContent";
import Footer from "../Footer/Footer";
import "../Header/Header.css";
import "./MainContent.css";
import "../Footer/Footer.css";
import "../App/App.css";

const MainPage = () => {
  return (
    <div className="container">
      <div>
        <Header />
        <MainContent />
        <Footer />
      </div>
    </div>
  );
};

export default MainPage;
