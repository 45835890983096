import React, { useState, useEffect } from "react";
import "./Header.css";
import logoImage from "../logo.jpg";
import { useDarkMode } from "../App/DarkModeContext";
import { Link } from "react-router-dom";

const Header = () => {
  const [shrink, setShrink] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setShrink(true);
    } else {
      setShrink(false);
    }
  };

  const Sidebar = ({ isOpen, toggle }) => {
    return (
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/blogs">Blogs</Link>
        <Link to="/contact">Contact Me</Link>
        <div className="close-btn" onClick={toggle}>
          ✖
        </div>
      </div>
    );
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`header-container ${shrink ? "shrink" : ""} ${
        isDarkMode ? "dark" : ""
      }`}
    >
      <div className="logo">
        <img src={logoImage} alt="YourLogo" />
      </div>

      <div className={`nav-links ${isDarkMode ? "dark" : ""}`}>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/blogs">Blogs</Link>
        <Link to="/contact">Contact Me</Link>
      </div>

      <div className="controls">
        <div
          className={`hamburger-menu ${isDarkMode ? "dark" : ""}`}
          onClick={toggleSidebar}
        >
          ☰
        </div>
        <div className="dark-toggle" onClick={toggleDarkMode}>
          {isDarkMode ? "🌞" : "🌙"}
        </div>
      </div>

      <Sidebar isOpen={isSidebarOpen} toggle={toggleSidebar} />
    </div>
  );
};

export default Header;
