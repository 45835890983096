// src/blogs/Blog1.js
import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useDarkMode } from "../App/DarkModeContext";
import "../Header/Header.css";
import "../Footer/Footer.css";
import "../App/App.css";
import "./Blogs.css";

const Blog1 = () => {
  const { isDarkMode } = useDarkMode();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className={`container ${isDarkMode ? "dark" : ""}`}>
        <div className={`blog-container ${isDarkMode ? "dark" : ""}`}>
          <h1>From Mechanical Gears to Cloud Frontier</h1>
          <p>
            Introduction:
            <br />
            Life is an incredible journey filled with twists and turns, and my
            own path has been no exception. Today, I want to share with you my
            remarkable career transition journey, a story of self-discovery,
            resilience, and the pursuit of passion. In this blog post, I'll walk
            you through the pivotal moments that led me to take a leap of faith,
            leaving behind my mechanical engineering background to venture into
            the world of IT as a Cloud Engineer and DevOps enthusiast.
          </p>
          <p>
            Embracing the Desire for Change:
            <br />
            As a fresh mechanical engineering graduate, I embarked on a
            promising career in the automotive industry. While I found joy in
            some aspects of my work, I couldn't ignore the lingering curiosity
            for the rapidly evolving realm of cloud computing and DevOps. This
            spark of interest ignited my determination to explore a new domain
            and uncover my true passions.
          </p>
          <p>
            Discovering My North Star - Cloud Engineering:
            <br />
            Fuelled by a desire to upskill and with unwavering support from my
            mentors, I embarked on a transformative journey of learning. Armed
            with the AWS Cloud Practitioner certificate and completing various
            courses in various DevOps tools, Linux, and Python, I embraced my
            newfound knowledge with zeal. Each step brought me closer to the
            realization that cloud engineering was my North Star, guiding me
            towards a fulfilling career transition.
          </p>
          <p>
            Navigating the Uncertainty:
            <br />
            Transitioning careers can be daunting, especially without prior
            experience in the IT industry. But with courage and persistence, I
            pushed forward. I devoted countless hours to honing my skills in
            AWS, networking fundamentals, and DevOps practices. My journey
            wasn't without challenges, but every obstacle presented an
            opportunity for growth and learning.
          </p>
          <p>
            The Power of Passion and Perseverance:
            <br />
            As I delved deeper into my passion for cloud computing, my
            motivation soared. I discovered that my background in project
            engineering and problem-solving from my mechanical engineering days
            were transferable skills that complemented my newfound IT expertise.
            The synergy between these skill sets amplified my capabilities as I
            immersed myself in AWS architecture and related projects.
          </p>
          <p>
            A New Beginning:
            <br />
            Today, I stand proud as a Cloud Engineer and DevOps enthusiast. The
            decision to transition my career has been a life-changing one, and I
            am grateful for the opportunities and growth it has brought into my
            life. My journey has taught me that embracing change and following
            your passions are the keys to unlocking your fullest potential.
          </p>
          <p>
            Conclusion:
            <br />
            My career transition has been a testament to the power of believing
            in oneself and embracing the unknown. It is a reminder that change
            is not something to be feared but embraced, for it has the potential
            to unlock doors we never knew existed. I hope my story inspires you
            to take that leap of faith in your own journey and pursue the path
            that truly ignites your soul.
          </p>
          <p>
            Remember, life is too short to settle for anything less than what
            sets your heart on fire.
          </p>
          <p>Vallabhaneni Sai Harsha</p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Blog1;
