import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useDarkMode } from "../App/DarkModeContext";
import "../Header/Header.css";
import "../Footer/Footer.css";
import "../App/App.css";
import "./Blogs.css";

const Blog2 = () => {
  const { isDarkMode } = useDarkMode();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className={`container ${isDarkMode ? "dark" : ""}`}>
        <div className={`blog-container ${isDarkMode ? "dark" : ""}`}>
          <h1>Journey to the Cloud: The AWS Cloud Resume Challenge</h1>
          <p>
            Introduction:
            <br />
            Welcome to my blogging website, where I'm thrilled to share my
            exciting journey in completing the AWS Cloud Resume Challenge. In
            this project, I had the opportunity to design, build, and deploy a
            stunning static web application from scratch using a range of AWS
            services, including S3, CloudFront, Lambda, DynamoDB, and Route 53.
            Additionally, I will walk you through how I used GitHub Actions for
            CI/CD pipelines, CloudWatch for monitoring, and how I modified a
            free web template to meet my unique needs. One of the standout
            features of my project is the implementation of a Lambda function to
            track viewer counts and store them in DynamoDB, displaying the
            results proudly on the webpage. Let's delve into the process step by
            step!.
          </p>
          <p>
            What is the AWS Cloud Resume Challenge?
            <br />
            The AWS Cloud Resume Challenge is a project-based learning
            initiative designed to empower developers and cloud enthusiasts to
            put their skills to the test by building and deploying a serverless
            web application on AWS. The challenge encourages participants to
            create a static website showcasing their resume or portfolio and
            host it using AWS services like AWS Lambda, Amazon S3, AWS
            CloudFront, and AWS DynamoDB.
          </p>
          <p>
            The beauty of this challenge is that it revolves around utilizing
            AWS Free Tier resources, making it both cost-effective and
            accessible to learners of all backgrounds.
          </p>
          <p>
            Choosing the Perfect Template:
            <br />
            To kick-start my AWS Cloud Resume Challenge, I scoured the internet
            for a visually appealing and well-structured web template. After
            careful consideration, I found a free template that resonated with
            my vision for the project. The template's HTML, CSS, and JavaScript
            structure formed the foundation of my static web application.
            However, to personalize it and make it truly my own, I delved deep
            into the code, making tailored modifications to align with my
            preferences and showcase my skills.
          </p>
          <p>
            AWS S3: Hosting the Static Web Application:
            <br />
            With my customized template ready, I turned to AWS S3 to host my
            static web application. Leveraging the simplicity and scalability of
            S3, I uploaded all the modified HTML, CSS, and JavaScript files,
            ensuring the website's smooth functionality and quick access.
          </p>
          <p>
            CloudFront: Accelerating Content Delivery:
            <br />
            To enhance the website's performance and reduce latency, I
            implemented AWS CloudFront as a content delivery network. This
            allowed users to access my web application from edge locations
            worldwide, optimizing loading times and delivering an improved user
            experience.
          </p>
          <p>
            DynamoDB: Tracking Viewer Count:
            <br />
            One of the exciting challenges I tackled in this project was
            tracking the viewer count on my website. To achieve this, I utilized
            AWS Lambda, a serverless compute service, to create a function that
            increments the viewer count every time someone visits the site. The
            value was then stored in DynamoDB, a fully managed NoSQL database,
            ensuring the data was reliable and accessible for further analysis.
          </p>
          <p>
            Route 53: Domain Name Management:
            <br />
            To give my project a professional touch, I acquired a custom domain
            name and used AWS Route 53 for domain name registration and
            management. This streamlined the process of associating the domain
            with my S3 bucket and CloudFront distribution, making the website
            easily accessible to users.
          </p>
          <p>
            CI/CD with GitHub Actions:
            <br />
            Automating the deployment process was crucial to maintaining a
            smooth development workflow. I integrated GitHub Actions to create
            CI/CD pipelines, allowing for seamless updates and automatic
            deployments whenever I pushed new changes to my GitHub repository.
            This approach saved time and effort, empowering me to focus on
            improving the website's content and features.
          </p>
          <p>
            CloudWatch: Monitoring and Troubleshooting:
            <br />I set up CloudWatch to monitor my website's performance, track
            metrics, and set alarms for critical events.
          </p>
          <p>
            Conclusion:
            <br />
            The AWS Cloud Resume Challenge provided an incredible learning
            experience, allowing me to demonstrate my proficiency in designing,
            building, and deploying static web applications on AWS. By combining
            the power of S3, CloudFront, Lambda, DynamoDB, Route 53, GitHub
            Actions, and CloudWatch, I created a polished and fully functional
            website that showcased my skills and achievements. The
            Lambda-powered viewer count feature, backed by DynamoDB, not only
            impressed visitors but also provided valuable insights into user
            engagement. This project has deepened my understanding of AWS
            services and their seamless integration, empowering me to take on
            more ambitious projects in the future. Thank you for joining me on
            this journey, and I hope my experience inspires you to embark on
            your own AWS adventures!
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog2;
