import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useDarkMode } from "../App/DarkModeContext";
import "./BlogsPage.css";
import { Link } from "react-router-dom";
import "../MainPage/MainContent.css";

const BlogsPage = () => {
  const { isDarkMode } = useDarkMode();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const blogs = [
    {
      id: 1,
      title: "From Mechanical Gears to Cloud Frontier",
      date: "2023-09-01",
      description:
        "In an empowering career transition, I shifted from a mechanical engineering background to pursue my passion as a Cloud Engineer and DevOps enthusiast. Embracing change and venturing into uncharted territories, I am on a journey of growth, learning, and endless possibilities in the dynamic world of cloud computing.",
    },
    {
      id: 2,
      title: "Journey to the Cloud: The AWS Cloud Resume Challenge",
      date: "2023-09-22",
      description:
        "Step into the captivating universe of cloud computing with me as I take on the AWS Cloud Resume Challenge. Witness my hands-on approach to building a fully functional and interactive resume using AWS services. From deploying a static website with S3 to integrating a contact form with Lambda and API Gateway, I'll share valuable insights and lessons learned during this challenging but rewarding project.",
    },
    {
      id: 3,
      title: "Unleashing the Power of Python: Web Scraping Adventures",
      date: "2023-07-20",
      description:
        "Explore the art of web scraping with Python as I uncover the endless possibilities of extracting valuable data from websites. Join me on thrilling expeditions, from scraping real-time information to automating data extraction with BeautifulSoup and Requests. Learn how web scraping can be a game-changer for data analysis, research, and staying ahead in a fast-paced digital landscape.",
    },
  ];

  return (
    <>
      <Header />
      <div className={`blogs-page-container ${isDarkMode ? "dark" : ""}`}>
        <div className={`blogs-heading ${isDarkMode ? "dark" : ""}`}>BLOGS</div>
        <div className={`latest-blogs ${isDarkMode ? "dark" : ""}`}>
          <div className="latest-blogs-wrapper">
            {blogs.map((blog) => (
              <div
                key={blog.id}
                className={`latest-blog-card ${isDarkMode ? "dark" : ""}`}
              >
                <div className="blog-content">
                  <h3>{blog.title}</h3>
                  <small>Date: {blog.date}</small>
                  <p>{blog.description}</p>
                  <Link to={`/blog/${blog.id}`} className="learn-more-btn">
                    Learn More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogsPage;
